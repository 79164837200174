<template>
  <div
    class="modal fade"
    id="modalPrintInvoice"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-body mt-3">
            <div class="payment-invoice">
              <div class="row text-right">
                <div class="col-md-12">
                  <button class="btn btn-export">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.2929 2.29289C11.6834 1.90237 12.3166 1.90237 12.7071 2.29289L16.7071 6.29289C17.0976 6.68342 17.0976 7.31658 16.7071 7.70711C16.3166 8.09763 15.6834 8.09763 15.2929 7.70711L13 5.41421V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V5.41421L8.70711 7.70711C8.31658 8.09763 7.68342 8.09763 7.29289 7.70711C6.90237 7.31658 6.90237 6.68342 7.29289 6.29289L11.2929 2.29289ZM5 17C5.55228 17 6 17.4477 6 18V20H18V18C18 17.4477 18.4477 17 19 17C19.5523 17 20 17.4477 20 18V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V18C4 17.4477 4.44772 17 5 17Z"
                        fill="#68CAF8"
                      />
                    </svg>
                    Export PDF
                  </button>
                </div>
              </div>
              <div class="row mt-4 align-items-center">
                <div class="col-md-6">
                  <div class="title-invoice">Finanta</div>
                </div>
                <div class="col-md-6 text-right address-company">
                  Jl. Pasopati, Kauman, Tamanan, Kec. Banguntapan, Kabupaten
                  Bantul, Daerah Istimewa Yogyakarta
                </div>
              </div>
              <hr style="margin: 0px 0px 10px 0px" />
              <div class="card-detail-invoice">
                <div class="row">
                  <div class="col-md-6">
                    <div class="text">Invoice</div>

                    <div class="text">#0001</div>
                    <div class="text">Tanggal : 04 Januari 2022</div>
                    <div class="text">Due Date : 07 Januari 2022</div>
                  </div>
                  <div class="col-md-6 text-right">
                    <div class="text">Dikirim untuk</div>
                    <div class="text">Osama Bin Kurniawan</div>
                    <div class="text">PT. Anak Bangsa Cerdas Digital</div>

                    <div class="text">Yogyakarta, Indonesia</div>
                  </div>
                </div>
              </div>
              <div class="row mt-4 info-detail">
                <div class="col-12">
                  <div class="title">Item Detail</div>
                  <!-- <div class="sub-title">Masukan item yang ingin dibayar</div> -->
                </div>
              </div>

              <div class="row mt-4 detail-company">
                <div class="col-md-12">
                  <table class="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th style="width: 20%">Nama Item</th>
                        <th style="width: 20%" class="text-right">Nilai</th>
                        <th style="width: 5%">Qty</th>
                        <th style="width: 20%" class="text-right">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Design & Implementation</td>
                        <td class="text-right">250,000,000</td>
                        <td>1</td>
                        <td class="text-right">250,000,000</td>
                      </tr>
                      <tr>
                        <td>Survey</td>
                        <td class="text-right">250,000,000</td>
                        <td>1</td>
                        <td class="text-right">250,000,000</td>
                      </tr>
                      <tr>
                        <td>UI/UX Design</td>
                        <td class="text-right">250,000,000</td>
                        <td>1</td>
                        <td class="text-right">250,000,000</td>
                      </tr>
                      <tr>
                        <td>Front-end Development</td>
                        <td class="text-right">250,000,000</td>
                        <td>1</td>
                        <td class="text-right">250,000,000</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="row">
                    <div class="col-md-4 offset-md-8 text-right align-self-end">
                      <div
                        class="d-flex flex-row-reverse justify-content-between"
                        style="padding-right: 25px"
                      >
                        <div class="amount font-weight-normal">
                          Rp. 1,000,000,000
                        </div>
                        <div class="sub-total font-weight-normal">
                          Sub Total
                        </div>
                      </div>
                      <div
                        class="d-flex flex-row-reverse justify-content-between mt-3"
                        style="padding-right: 25px"
                      >
                        <div class="amount font-weight-normal">
                          Rp. 5,000,000
                        </div>
                        <div class="sub-total">Pajak</div>
                      </div>
                      <hr />
                      <div
                        class="d-flex flex-row-reverse justify-content-between mt-3"
                        style="padding-right: 25px"
                      >
                        <div class="amount font-weight-normal">
                          Rp. 1,005,000,000
                        </div>
                        <div class="sub-total">Total</div>
                      </div>
                      <button
                        class="btn btn-add-payment mt-3"
                        @click="paymemtProject"
                        style="width: 100%"
                      >
                        Pembayaran
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="">
            <h4 class="title">Edit Project</h4>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/data-project">Project </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Edit</li>
              </ol>
            </nav>
          </div>
          <div>
            <button class="btn btn-save">Simpan</button>
          </div>
        </div>

        <div class="card info-detail">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <router-link to="/data-project/detail" class="nav-link"
                >Detail
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/data-project/detail/anggota" class="nav-link"
                >Anggota</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/data-project/detail/RAB" class="nav-link"
                >RAB</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/data-project/detail/laporan" class="nav-link"
                >Laporan</router-link
              >
            </li>
            <li class="nav-item mr-auto">
              <router-link
                to="/data-project/detail/pembayaran"
                class="nav-link active"
                >Pembayaran
              </router-link>
            </li>
          </ul>
          <div class="row mt-5">
            <div class="col-6">
              <div class="title">Form Pembayaran Project</div>
              <div class="sub-title">Edit informasi yang diperlukan</div>
            </div>
            <div class="col-6 text-right">
              <router-link
                class="btn btn-add"
                to="/data-project/detail/pembayaran/tambah-invoice"
                >Buat Invoice</router-link
              >
            </div>
          </div>
          <hr />
          <div class="table-responsive mt-4">
            <table
              class="table table-bordered table-hover table-striped"
              id="tableDepartment"
            >
              <thead>
                <tr>
                  <th style="width: 20%">Nomor Invoice</th>
                  <th style="width: 10%">Jatuh Tempo</th>
                  <th style="width: 25%">Nilai</th>
                  <th style="width: 5%">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-on:click="detail"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Klik untuk melihat detail"
                >
                  <td>003456</td>
                  <td>20 Jan 2022</td>
                  <td class="text-right">250,000,000</td>
                  <td>
                    <div class="green-bedge">Paid</div>
                  </td>
                </tr>
                <tr
                  v-on:click="detail"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Klik untuk melihat detail"
                >
                  <td>003456</td>
                  <td>20 Jan 2022</td>
                  <td class="text-right">250,000,000</td>
                  <td>
                    <div class="green-bedge">Paid</div>
                  </td>
                </tr>
                <tr
                  v-on:click="detail"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Klik untuk melihat detail"
                >
                  <td>003456</td>
                  <td>20 Jan 2022</td>
                  <td class="text-right">250,000,000</td>
                  <td>
                    <div class="green-bedge">Paid</div>
                  </td>
                </tr>
                <tr
                  v-on:click="detail"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Klik untuk melihat detail"
                >
                  <td>003456</td>
                  <td>20 Jan 2022</td>
                  <td class="text-right">250,000,000</td>
                  <td>
                    <div class="red-bedge">Unpaid</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
// import Select2 from 'vue3-select2-component';
// import Datepicker from 'vue3-date-time-picker';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { ref } from "vue";
import $ from "jquery";

export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    // Select2,
    // Datepicker
  },

  mounted() {
    this.tooltip();
  },

  data() {
    return {
      myValue: "aaaa",
      statusCompany: ["Aktif", "Tidak Aktif"], // or [{id: key, text: value}, {id: key, text: value}]
      optionPeriode: ["1 Bulan", "2 Bulan"],
      OptionsDistrict: ["Solo", "Klaten"],
      OptionsDistricts: ["Jebres", "Banjarsari"],
      OptionsProvince: ["Jawa Tengah", "Yogyakarta"],
      optionsCategory: ["Sound System", "Software House"],
      dateStart: null,
      dateImplementation: null,
      dateEnd: null,
      placeholder: [
        {
          text: "kecamatan",
        },
      ],
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  methods: {
    paymemtProject() {
      this.$router.push("/data-project/detail/pembayaran/pembayaran-project");
      $("#modalPrintInvoice").modal("hide");
    },
    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    detail() {
      $("#modalPrintInvoice").modal("show");
      $('[data-toggle="tooltip"]').tooltip("hide");
    },

    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text }) {
      console.log({
        id,
        text,
      });
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.btn-add-title {
  background-color: #d7ffea;
  color: #3cb778;
  font-weight: 500;
}

.btn-add {
  background-color: #3cb778;
  color: #fff !important;
  padding: 7px 12px;
  font-weight: 400 !important;
  border-radius: 5px;
  text-decoration: none !important;
  font-size: 15px;
  border: none;
}

.modal table {
  border-right: none;
  border-left: none;
}

.modal .table td {
  padding: 0 25px !important;
  vertical-align: middle;
  font-size: 14px;
  border: none !important;
}

.modal .table th {
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #eceff1 !important;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #eceff1;
}

.table td,
.table th {
  padding: 0 25px !important;
  vertical-align: middle;
  font-size: 14px;
  border-top: 1px solid #dee2e6;
}

.btn-add-row {
  color: #f8ce3a;
  background-color: #fff3c7;
  font-weight: 500;
}

.title-item {
  background-color: #eceff1;
}

.title-rab {
  background-color: #2e3a59;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.btn-save {
  background-color: #40ddd4;
  color: #fff;
  /* width: 90px; */
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0px;
}

.btn-add {
  padding: 10px 10px;
  margin-left: 20px;
}

.nav-pills .nav-item .nav-link.active[data-v-161fae0a]:after {
  border-radius: 5px !important;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.modal-dialog {
  max-width: 800px;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

.modal-content {
  overflow-y: auto;
  height: 100%;
  border-radius: 0px;
}

/* detail invoice */
.btn-lampiran {
  background-color: #f9ffd7;
  color: #b9b201;
  font-weight: 400;
}

#modalAddPayment .btn-save {
  background-color: transparent !important;
  color: #68caf8 !important;
  border: 1px solid #68caf8 !important;
  font-weight: 400;
  font-size: 15px;
  padding: 10px 0px;
}

#modalAddPayment .btn-save:hover {
  background-color: #68caf8 !important;
  color: #fff !important;
}

.card-detail-invoice {
  background: linear-gradient(103.2deg, #6ec0fe 0%, #4df3dd 100%);
  border-radius: 5px;
  color: #fff;
  padding: 10px 20px;
  line-height: 30px;
}

#modalPrintInvoice .modal-dialog {
  max-width: 1162px;
}

.modal .table td {
  height: 44px !important;
}

.btn-add-payment {
  background-color: #d7ffea;
  color: #3cb778;
  padding: 5px 10px;
  font-weight: 500;
  margin-right: 15px;
}

.btn-print-invoice {
  background-color: #f9ffd7;
  color: #b9b201;
  padding: 5px 10px;
  font-weight: 500;
}

.address-company {
  font-size: 12px;
  color: #263238;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.noEstimated {
  font-size: 12px;
  color: #898989;
}

.form-label {
  max-width: 134px;
  width: 200px;
}

.card-total {
  display: flex;
}

.payment-invoice {
  padding: 0px 46px;
}

.sub-total {
  margin-right: 50px;
  font-size: 14px;
}

.amount {
  font-size: 14px;
}

.name-company {
  font-size: 18px;
  font-weight: 500;
}

.detail-company .value {
  color: #263238;
  font-size: 16px;
  font-weight: 400;
}

.detail-company .text {
  color: #cdcdcd;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.title-invoice {
  font-weight: 700;
  font-size: 50px;
  background: linear-gradient(102.18deg, #6ec0fe -17.12%, #61efe7 95.5%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.total-payment {
  font-size: 24px;
  font-weight: 600;
  margin-top: 10px;
}

/* .text {
        margin-top: 10px;
    } */

.status-invoice-paid {
  background: #d7ffea;
  color: #3cb778;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 50px;
  border: none;
  cursor: default;
  border-radius: 5px;
}

.btn-export {
  background: #e4efff;
  color: #68caf8;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 10px;
  border-radius: 5px;
  border: none;
}

.modal .btn-print-invoice {
  background: #f9ffd7;
  color: #b9b201;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 23px;
  border-radius: 5px;
  border: none;
}

.btn-invoice {
  background: #fff3c7;
  color: #f8ce3a;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 15px;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}

/* End Detail Invoice */
</style>
